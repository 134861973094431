import "./scss/styles.scss";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

function mainCarousel() {
    jQuery('.card-container-carousel').owlCarousel({
        loop: true,
        items: 3,
        nav: true,
        autoplay: true,
        responsiveClass: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        margin: 10,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,

            },
            1400: {
                items: 3
            }
        }
    });
}


$(document).ready(function () {
    console.log("Ready")
    mainCarousel();

    $('.faq h3').click(function () {
        var current = $(this).parent();
        console.log(current)
        var isActive = current.hasClass('active');
        $('.faq .answer').slideUp();
        $('.faq').removeClass('active');

        if (!isActive) {
            current.addClass('active');
            current.find('.answer').slideDown();
        }
    });

    $('nav a').click(function () {
        $('nav a').removeClass('active');
        $(this).addClass('active');
    });

    $('.navbar-burger').click(function () {
        console.log("click")
        $(this).toggleClass('active');
        $('.navbar-menu').toggleClass('active');
    });

    $("#openModal").on("click", function () {
        $(".modal").fadeIn();
        $("body").addClass("modal-open");
    });

    // Cerrar modal al hacer clic en la "X" de cierre
    $(".close").on("click", function () {
        $(".modal").fadeOut();
        $("body").removeClass("modal-open");
    });

    var $navbarLinks = $("nav ul li a");

    // Manejador de eventos para hacer clic en los enlaces del navbar
    $navbarLinks.on("click", function (event) {
        // Evita el comportamiento predeterminado del enlace
        event.preventDefault();

        // Obtiene el ID del elemento asociado al enlace
        var target = $(this).attr("href");

        // Realiza la animación de desplazamiento suave hacia el elemento objetivo
        $("html, body").animate({
            scrollTop: $(target).offset().top - 100
        }, 1000);

        // Cierra menú hamburguesa
        $('.navbar-burger').trigger("click")
    });

    hbspt.forms.create({
        region: "na1",
        portalId: "3942150",
        formId: "7f6d0f2b-a720-4b12-8c6a-231566c077cb",
        target: "#hubspot-form"
    });
})
